<script>
import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";
import axios from "axios";
import Swal from "sweetalert2";

SwiperCore.use([Thumbs, Pagination, Navigation]);

export default {
  page: {
    title: "Statistics by companies",
    meta: [{name: "description", content: 'Statistics by companies'}],
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    company_ids: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      companies: [],
      view: 'horizontal'
    };
  },
  computed: {
    totalBalance() {
      return this.companies.reduce((acc, company) => {
        return acc + (parseFloat(company.total_payment_price || 0) - parseFloat(company.total_issued_price || 0) - parseFloat(company.total_reserved_price || 0))
      }, 0)
    },
    totalIssuedPrice() {
      return this.companies.reduce((acc, company) => {
        return acc + parseFloat(company.total_issued_price || 0)
      }, 0)
    },
    totalPaymentsPrice() {
      return this.companies.reduce((acc, company) => {
        return acc + parseFloat(company.total_payment_price || 0)
      }, 0)
    },
    totalReservedPrice() {
      return this.companies.reduce((acc, company) => {
        return acc + parseFloat(company.total_reserved_price || 0)
      }, 0)
    },
  },
  methods: {
    async loadStatistics() {
      try {
        let response = await axios.get(`/customer/list_by_ids/?company_ids=${this.company_ids.join(',')}`)
        this.companies = response.data || []
      } catch {
        alert("error while loading statistics by order companies")
      }
    },
    async downloadDataAsExcel() {
      try {
        let response = await axios.get(`/statistic/customer_balance_report/?company_ids=${this.company_ids.join(',')}`, {
          responseType: 'blob'
        })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'statistics.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch {
        await Swal.fire({
          title: 'Error!',
          text: 'Something went wrong',
          icon: 'error',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      }
    }
  },
  watch: {
    company_ids: {
      handler: function (newValue) {
        if (newValue) {
          this.loadStatistics()
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <swiper
      class="default-swiper rounded"
      :loop="false"
      :grabCursor="true"
      :slidesPerView="4"
      :spaceBetween="15"
      v-if="view === 'horizontal'"
  >
    <swiper-slide>
      <b-card no-body class="mb-0 border-0 shadow-none border-end">
        <b-card-body class="pb-2">
          <div class="flex-grow-1">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="fs-16 mb-1">Statistics</h5>
              <div class="d-flex gap-2 align-items-center">
                <i @click="downloadDataAsExcel()" class="mdi mdi-file-excel text-success fs-5 cursor-pointer"></i>
                <div class="dropdown">
                  <button
                      type="button"
                      class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle btn-sm"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    <i class="mdi mdi-dots-vertical fs-4"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item py-2"
                       @click="view = 'horizontal'"
                       :class="{
                    'active': view === 'horizontal'
                     }"
                    >
                      <span class="align-middle">Horizontal</span>
                    </a>
                    <a class="dropdown-item py-2"
                       @click="view = 'vertical'"
                       :class="{
                    'active': view === 'vertical'
                     }"
                    >
                      <span class="align-middle">Vertical</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-muted mb-1">
              <span>Balance: </span>
              <span class="fw-medium ms-1"
                    :class="{
                'text-success': totalBalance >=0,
                'text-danger': totalBalance < 0
                    }"
              >$
              {{
                  totalBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Issued Price: </span>
              <span class="fw-medium ms-1">$
              {{
                  totalIssuedPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Payment Price: </span>
              <span class="fw-medium ms-1">$
              {{
                  totalPaymentsPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Reserved Price: </span>
              <span class="fw-medium ms-1">$
              {{
                  totalReservedPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Total Companies: </span>
              <span class="fw-medium ms-1">
              {{
                  companies.length
                }}
              </span>
            </p>
          </div>
        </b-card-body>
      </b-card>
    </swiper-slide>
    <swiper-slide v-for="(data, index) of companies" :key="index">
      <b-card no-body class="mb-0 border-0 shadow-none" :class="{
        'border-end': index !== companies.length - 1}">
        <b-card-body class="pb-2">
          <div class="flex-grow-1">
            <router-link :to="{
              name: 'customer_profile_contracts',
              params: {
                slug: data.slug
              }
            }"
                         class="d-flex align-items-center gap-2 mb-3">
              <div class="avatar-xs rounded-circle bg-soft-secondary d-flex justify-content-center align-items-center">
                <span class="text-secondary">{{ data.name.charAt(0) }}</span>
              </div>
              <a>
                <h5 class="fs-16 mb-1 ">{{ data.name }}</h5>
              </a>
            </router-link>
            <p class="text-muted mb-1">
              <span>Balance: </span>
              <span class="fw-medium ms-1"
                    :class="{
                'text-success': (parseFloat(data.total_payment_price || 0) - parseFloat(data.total_issued_price || 0) - parseFloat(data.total_reserved_price || 0)) >=0,
                'text-danger': (parseFloat(data.total_payment_price || 0) - parseFloat(data.total_issued_price || 0) - parseFloat(data.total_reserved_price || 0)) < 0
                    }"
              >$
              {{
                  (parseFloat(data.total_payment_price || 0) - parseFloat(data.total_issued_price || 0) - parseFloat(data.total_reserved_price || 0)).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Issued price: </span>
              <span class="fw-medium ms-1">$ {{
                  parseFloat(data.total_issued_price || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}</span>
            </p>
            <p class="text-muted mb-1">
              <span>Payments price: </span>
              <span class="fw-medium ms-1">$ {{
                  parseFloat(data.total_payment_price || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}</span>
            </p>
            <p class="text-muted mb-1">
              <span>Reserved price: </span>
              <span class="fw-medium ms-1">$ {{
                  parseFloat(data.total_reserved_price || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}</span>
            </p>
          </div>
        </b-card-body>
      </b-card>
    </swiper-slide>
  </swiper>

  <div v-else class="row g-4">
    <div class="col-12 col-sm-6 col-md-4 col-xl-3">
      <b-card no-body class="mb-0 border-0 shadow-none border-end">
        <b-card-body class="pb-2">
          <div class="flex-grow-1">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="fs-16 mb-1">Statistics</h5>
              <div class="d-flex gap-2 align-items-center">
                <i @click="downloadDataAsExcel()" class="mdi mdi-file-excel text-success fs-5 cursor-pointer"></i>
                <div class="dropdown">
                  <button
                      type="button"
                      class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle btn-sm"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    <i class="mdi mdi-dots-vertical fs-4"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item py-2"
                       @click="view = 'horizontal'"
                       :class="{
                    'active': view === 'horizontal'
                     }"
                    >
                      <span class="align-middle">Horizontal</span>
                    </a>
                    <a class="dropdown-item py-2"
                       @click="view = 'vertical'"
                       :class="{
                    'active': view === 'vertical'
                     }"
                    >
                      <span class="align-middle">Vertical</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-muted mb-1">
              <span>Balance: </span>
              <span class="fw-medium ms-1"
                    :class="{
                'text-success': totalBalance >=0,
                'text-danger': totalBalance < 0
                    }"
              >$
              {{
                  totalBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Issued Price: </span>
              <span class="fw-medium ms-1">$
              {{
                  totalIssuedPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Payment Price: </span>
              <span class="fw-medium ms-1">$
              {{
                  totalPaymentsPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Reserved Price: </span>
              <span class="fw-medium ms-1">$
              {{
                  totalReservedPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Total Companies: </span>
              <span class="fw-medium ms-1">
              {{
                  companies.length
                }}
              </span>
            </p>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3" v-for="(data, index) of companies" :key="index">
      <b-card no-body class="mb-0 border-0 shadow-none" :class="{
        'border-end': index !== companies.length - 1}">
        <b-card-body class="pb-2">
          <div class="flex-grow-1">
            <router-link :to="{
              name: 'customer_profile_contracts',
              params: {
                slug: data.slug
              }
            }"
                         class="d-flex align-items-center gap-2 mb-3">
              <div class="avatar-xs rounded-circle bg-soft-secondary d-flex justify-content-center align-items-center">
                <span class="text-secondary">{{ data.name.charAt(0) }}</span>
              </div>
              <a>
                <h5 class="fs-16 mb-1 ">{{ data.name }}</h5>
              </a>
            </router-link>
            <p class="text-muted mb-1">
              <span>Balance: </span>
              <span class="fw-medium ms-1"
                    :class="{
                'text-success': (parseFloat(data.total_payment_price || 0) - parseFloat(data.total_issued_price || 0) - parseFloat(data.total_reserved_price || 0)) >=0,
                'text-danger': (parseFloat(data.total_payment_price || 0) - parseFloat(data.total_issued_price || 0) - parseFloat(data.total_reserved_price || 0)) < 0
                    }"
              >$
              {{
                  (parseFloat(data.total_payment_price || 0) - parseFloat(data.total_issued_price || 0) - parseFloat(data.total_reserved_price || 0)).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </span>
            </p>
            <p class="text-muted mb-1">
              <span>Issued price: </span>
              <span class="fw-medium ms-1">$ {{
                  parseFloat(data.total_issued_price || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}</span>
            </p>
            <p class="text-muted mb-1">
              <span>Payments price: </span>
              <span class="fw-medium ms-1">$ {{
                  parseFloat(data.total_payment_price || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}</span>
            </p>
            <p class="text-muted mb-1">
              <span>Reserved price: </span>
              <span class="fw-medium ms-1">$ {{
                  parseFloat(data.total_reserved_price || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}</span>
            </p>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>