<script>
import CustomTable from "@/components/tables/table.vue";
import {ordersMehtods} from "@/state/helpers";
import Swal from "sweetalert2";
import PageHeader from "../../../../components/page-header.vue";
import EmptyWagonOrderPreviewModal from "./modals/OrderPreview.vue"
import StatisticsByCompanies from "@/views/pages/orders/components/StatisticsByCompanies.vue";
import store from "@/state/store";
import axios from "axios";

export default {
  data() {
    return {
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Empty Wagon Orders",
          active: true,
        },
      ],
      numberOfErrors: 0,
      headers: [
        {
          label: 'ORDER NUMBER',
          field: 'order_number',
          align: 'center',
          excel_data: (application) => {
            return application.order.order_number
          },
        },
        {
          label: 'LOT NUMBER',
          field: 'lot_number',
          align: 'center',
          excel_data: (application) => {
            return application.order.lot_number
          },
        },
        {
          label: 'POSITION',
          field: 'position',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Multi modal', value: 'multi_modal'},
            {label: 'Block train', value: 'block_train'},
            {label: 'Rail forwarder', value: 'rail_forwarder'},
          ],
          excel_data: (application) => {
            return application.order.position
          },
          align: 'center',
        },
        {
          label: 'FINANCE STATUS',
          field: 'finance_status',
          excel_data: (application) => {
            return application.finance_status.map(finance_status => finance_status.counterparty).join(', ')
          },
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Completed', value: 'true'},
            {label: 'Incomplete', value: 'false'},
          ],
        },
        {
          label: 'QUANTITY',
          field: 'quantity',
          excel_data: (application) => {
            return application.order.quantity || '-'
          },
          align: 'center',
        },
        {
          label: 'AGREED RATE',
          field: 'total_agreed_rate',
          excel_data: (application) => {
            return application.order.total_agreed_rate
          },
          align: 'center',
        },
        {
          label: 'Profitability Flag',
          field: 'profit_status',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Normal Profitability', value: 'correct'},
            {label: 'Excess Profitability', value: 'incorrect'},
          ],
          excel_data: (order) => {
            return order.order.profit_status
          },
        },
        {
          label: 'ORDER TYPE',
          field: 'type',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Import', value: 'import'},
            {label: 'Export', value: 'export'},
            {label: 'Transit', value: 'transit'},
          ],
          excel_data: (application) => {
            return application.order.type
          },
          align: 'center',
        },
        {
          label: 'INVOICE',
          field: 'invoice_issued',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Yes', value: 'true'},
            {label: 'No', value: 'false'},
          ],
          excel_data: (order) => {
            return order.order.invoice_issued ? 'Yes' : 'No'
          },
          align: 'center',
        },
        {
          label: 'COMPANY',
          field: 'company',
          excel_data: (application) => {
            return application.order.company ? application.order.company.name : '-'
          },
          align: 'center',
        },
        {
          label: 'PAYMENT STATUS',
          field: 'payment_status',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Reserved', value: 'reserved'},
            {label: 'Received', value: 'received'},
            {label: 'Issued', value: 'issued'},
          ],
          excel_data: (application) => {
            return application.order.payment_status
          },
          visible: false,
          align: 'center',
        },
        {
          label: 'DATE',
          field: 'date',
          excel_data: (application) => {
            return application.order.date
          },
          align: 'center',
        },
        {
          label: 'MANAGER',
          field: 'user',
          excel_data: (application) => {
            return application.order.user ? application.order.user.username : '-'
          },
          align: 'center',
        },
        {
          label: 'COMMENT',
          field: 'comment',
          excel_data: (application) => {
            return application.order.comment
          },
          align: 'center',
          visible: false
        },
        {
          label: 'ACTIONS',
          field: 'actions',
          align: 'center',
        },
      ],
      orders: [],
      isLoading: false,
      table: {
        url: `/wagon_empty_order/list/`,
      },
      pagination: {
        perPage: 10,
      },

      preview_order: {},

      order_company_ids: [],
      blacklisted_users_ids: []
    };
  },
  computed: {
    user() {
      return store.state.user
    }
  },
  methods: {
    ...ordersMehtods,
    setToUpdateOrder(order) {
      this.$router.push({name: 'orders_empty_wagon_update', params: {id: order.order.order_number}})
    },

    deleteOrderConfirmation(order) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to delete order ${(order.order_number).toString().toUpperCase()}`,
        text: 'Deleting your order will remove all of its information from our database.',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type Order${(order.order_number).toString().toUpperCase()} to confirm`,
        input: 'email',
        inputPlaceholder: `Order${(order.order_number).toString().toUpperCase()}`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'Order' + (order.order_number).toString().toUpperCase()) {
              resolve(this.deleteOrder(order))
            } else {
              resolve('Order number did not match :)')
            }
          })
        }
      });
    },

    async deleteOrder(order) {
      try {
        await axios.delete(`/wagon_empty_order/delete/${order.order_number}/`)
        await this.$refs.table.getData()
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Order has been deleted successfully',
          showConfirmButton: false,
          timer: 3000
        })
      } catch {
        await Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Couldn't delete the order`,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        })
      }
    },

    downloadFile(file) {
      if (!file) return alert("Invalid File")
      let url = process.env.VUE_APP_ORDER_URL + file
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },

    onDataLoaded(data) {
      try {
        this.order_company_ids = []
        data.forEach(o => {
          if (!this.order_company_ids.includes(o.order.company.id)) {
            this.order_company_ids.push(o.order.company.id);
          }
        });
      } catch {
        this.order_company_ids = []
      }
    }
  },
  components: {
    StatisticsByCompanies,
    CustomTable,
    PageHeader,
    EmptyWagonOrderPreviewModal
  },
  mounted() {
    if (this.blacklisted_users_ids.includes(this.user.id)) {
      this.headers = this.headers.filter(h => h.field !== 'total_agreed_rate')
    }
  }
};
</script>

<template>
  <PageHeader title="Orders" :items="items"/>
  <EmptyWagonOrderPreviewModal :order="preview_order"/>
  <div class="mb-4">
    <CustomTable
        ref="table"
        name="EMPTY WAGON ORDERS TABLE"
        id="empty_wagon_orders_table"
        :headers="headers"
        :selectable="true"
        :searchable="true"
        :url="table.url"
        @on-loaded="onDataLoaded($event)"
    >

      <template v-slot:top-right>
        <router-link :to="{name: 'create_empty_wagon'}">
          <button type="button" class="btn btn-success">
            Create empty wagon order
          </button>
        </router-link>
      </template>


      <template v-slot:header_div>
        <div class="mt-3">
          <StatisticsByCompanies :company_ids="order_company_ids"/>
        </div>
      </template>

      <template v-slot:order_number="props">
      <span class="badge badge-soft-secondary fs-12">
        <router-link :to="{name: 'orders_empty_wagon_detail', params: {id: props.row.order.order_number}}">
          {{ props.row.order.order_number }}
        </router-link>
      </span>
      </template>

      <template v-slot:lot_number="props">
        {{ props.row.order.lot_number }}
      </template>
      <template v-slot:position="props">
        {{ props.row.order.position }}
      </template>

      <template v-slot:finance_status="{row: order}">

        <router-link
            v-if="order.order.task_project"
            :to="{name: 'project_tasks_list', params: {
          project_id: order.order.task_project.id
        }}">
          <BProgress v-b-tooltip.hover
                     :title="`${order.order.task_project.task_completed_count} out of ${order.order.task_project.task_count} task completed  ✅`"
                     :value="((order.order.task_project.task_completed_count * 100) / order.order.task_project.task_count )"
                     variant="success" class="mb-2"></BProgress>
        </router-link>

        <b-progress>
          <b-progress-bar v-for="(counterparty, index) of order.finance_status"
                          :key="index"
                          :value="100 / (order.finance_status.length)"
                          v-b-tooltip.hover
                          :title="counterparty.counterparty"
                          class="border-end"
                          :variant="counterparty.status ? 'success' : 'danger'"
          />
        </b-progress>
      </template>

      <template v-slot:profit_status="{row: data}">
        <i v-b-tooltip.hover
           title="Order profitability significantly exceeds the agreed-rate. Please verify the accuracy of counterparty expenses and review the cost allocation for this order."
           v-if="data.order.profit_status && data.order.profit_status === 'Incorrect'"
           class="bx bx-error-circle text-danger align-middle fs-4">
        </i>
        <i v-else-if="data.order.profit_status === 'Correct'" class="bx bx-check-circle text-success align-middle fs-4"></i>
      </template>


      <template v-slot:type="props">
        {{ props.row.order.type }}
      </template>
      <template v-slot:payment_status="props">
        {{ props.row.order.payment_status }}
      </template>
      <template v-slot:date="props">
        {{ props.row.order.date }}
      </template>

      <template v-slot:quantity="{row: order}">
        <VTooltip>
        <span class="badge" :class="{
        'badge-outline-success' : order.order.filled_quantity === order.order.quantity,
        'badge-outline-danger' : order.order.filled_quantity < order.order.quantity,
      }">
          {{ order.order.filled_quantity + '/' + order.order.quantity }}
      </span>
          <template v-slot:popper>
            {{ order.order.filled_quantity || 0 }} rows filled out of {{ order.order.quantity || 0 }}
          </template>
        </VTooltip>
      </template>

      <template v-slot:invoice_issued="{row: order}">
        <div
            v-if=" order.order.invoice"
            @click="downloadFile(order.order.invoice.file)"
        >
          <div :class="{
               'text-success': order.order.invoice.status === 'paid',
               'text-warning': order.order.invoice.status === 'unpaid',
               'text-danger': order.order.invoice.status === 'canceled',
             }"
               v-b-tooltip :title="order.order.invoice.status"
               class="cursor-pointer"
          >
            <i class="ri-download-2-fill align-middle me-1"></i>
            {{ order.order.invoice.number }}
          </div>
          <small class="text-muted">
            {{ order.order.invoice.deadline ? 'Due ' + order.order.invoice.deadline : '' }}
          </small>
        </div>
        <span v-else>-</span>
      </template>

      <template v-slot:actions="slotProps">

        <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
          <b-button variant="light"
                    @click="preview_order = slotProps.row"
                    data-bs-toggle="modal"
                    data-bs-target="#empty_wagon_order_preview_modal">
            <i class="ri-eye-fill align-bottom fs-6"></i>
          </b-button>
          <b-button variant="light" class="p-0" @click="setToUpdateOrder(slotProps.row)">
            <div class="px-1">
              <i class="ri-pencil-fill align-bottom px-1"></i>
            </div>
          </b-button>
          <b-button variant="light" @click="deleteOrderConfirmation(slotProps.row.order)">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>
      </template>

      <template v-slot:total_agreed_rate="{row: order}">
        <div v-if="order.order.total_agreed_rate">
          ${{
            parseFloat(order.order.total_agreed_rate).toLocaleString(undefined, {
              minimumFractionDigits: 1, maximumFractionDigits: 2
            })
          }}

          <span class="text-danger"
                v-b-tooltip.hover title="Invoice price"
                v-if="order.order.invoice && (order.order.invoice ? order.order.invoice.total_cost : 0) !== parseFloat(order.order.total_agreed_rate || 0)">
            ({{
              parseFloat(order.order.invoice ? order.order.invoice.total_cost || 0 : 0).toLocaleString(undefined, {
                minimumFractionDigits: 1, maximumFractionDigits: 2
              })
            }})
          </span>

        </div>
        <span v-else>--</span>
      </template>

      <template v-slot:company="props">
        <span v-if="props.row.order.company === null">--</span>
        <div v-else>
          <router-link
              :to="{name: 'customer_profile', params: {slug: props.row.order.company.slug}}">
                    <span class="rounded-circle bg-soft-secondary text-secondary mx-1 px-2">
          {{ props.row.order.company.name.slice(0, 1) }}
        </span>
            <span>
          {{ props.row.order.company.name }}
        </span>
          </router-link>
        </div>
      </template>

      <template v-slot:user="{row: {order: order}}">
        <template v-if="order.user">
          <router-link :to="{name: 'user_profile', params: {slug: order.user.slug}}">
            <div class="d-flex align-items-center">
              <div class="avatar-xxs flex-shrink-0 me-1">
        <span class="avatar-title bg-light text-primary rounded-circle">
          {{ order.user.username[0].toUpperCase() }}
        </span>
              </div>
              <span>{{ order.user.username }}</span>
            </div>
          </router-link>
        </template>
        <span v-else>--</span>
      </template>

    </CustomTable>
  </div>
</template>